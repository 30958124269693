// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-explorer-js": () => import("./../../../src/pages/explorer.js" /* webpackChunkName: "component---src-pages-explorer-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pathways-to-mobility-js": () => import("./../../../src/pages/pathways-to-mobility.js" /* webpackChunkName: "component---src-pages-pathways-to-mobility-js" */),
  "component---src-pages-research-library-js": () => import("./../../../src/pages/research-library.js" /* webpackChunkName: "component---src-pages-research-library-js" */),
  "component---src-templates-research-topic-js": () => import("./../../../src/templates/research-topic.js" /* webpackChunkName: "component---src-templates-research-topic-js" */)
}

